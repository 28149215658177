<div class="relative">
  <div
    role="button"
    aria-label="Open user settings"
    (click)="handleToggleProfile()"
  >
    <liv-avatar size="md" [name]="username"></liv-avatar>
  </div>

  <div
    class="pointer-events-none invisible absolute right-0 z-20 block min-w-40 max-w-64 origin-top translate-y-0 scale-y-0 opacity-0 transition-all duration-300 ease-out before:absolute before:-top-2 before:right-3 before:size-0 before:border-8 before:border-t-0 before:border-transparent before:!border-b-neutral-hight-light [&.is-active]:pointer-events-auto [&.is-active]:!visible [&.is-active]:translate-y-4 [&.is-active]:scale-y-100 [&.is-active]:!opacity-100 [&.is-active]:transition-all [&.is-active]:duration-300 [&.is-active]:ease-in"
    [ngClass]="{
      'is-active': expandSettingsDropdown
    }"
  >
    <div
      class="overflow-hidden rounded bg-neutral-hight-light !p-2 shadow-tooltip"
    >
      @if (!isSpaceToBeUser) {
        @for (item of menuSettings; track $index) {
          @if ((item.permissions | hasPermission) && !item.isHidden) {
            <a
              [routerLink]="item.link"
              #settingsItem
              class="flex w-full items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-0 bg-none !p-2 text-sm/tight text-neutral-low-pure outline-0 transition-all duration-200 ease-linear hover:bg-neutral-low-medium/10"
            >
              <ng-icon [name]="item.icon" size="16" class="!mr-2"></ng-icon>
              {{ item.name }}

              @if (item.isNew) {
                <span
                  class="!ml-2 flex h-5 w-fit items-center justify-center !rounded-lg !border border-feedback-success-medium bg-feedback-success-pure/10 px-2 py-1 text-xs font-medium text-feedback-success-medium"
                >
                  Novo
                </span>
              }
            </a>
          }
        }
      }

      <button
        class="group flex w-full items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-0 bg-none !p-2 text-sm/tight text-neutral-low-pure outline-0 transition-all duration-200 ease-linear hover:bg-feedback-warning-pure/10 hover:text-feedback-warning-pure"
        (click)="onLogout()"
      >
        <ng-icon
          name="featherLogOut"
          size="16"
          class="!mr-2 group-hover:text-feedback-warning-pure"
        ></ng-icon>
        Sair
      </button>
    </div>
  </div>
</div>

<div
  class="overlay-panel"
  overlay
  (onClickOuside)="onClickOuside()"
  [activeOverlay]="expandSettingsDropdown"
></div>
